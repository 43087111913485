import React from "react";
import ReactDOM from "react-dom"
import App from "./App";
import { DAppProvider } from "./context";


ReactDOM.render(
    <DAppProvider>
      <App />
    </DAppProvider>,
  document.getElementById("root")
)
